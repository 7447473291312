import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '@ratedpeople/header';
import Footer from '@ratedpeople/footer';
import CookiePrivacyBanner from '@ratedpeople/cookie-privacy-banner';
import CookieBannerTemplate from '@ratedpeople/cookie-banner-template';

import TpSignUpBanner from '../TpSignUpBanner/index';

import './style.module.scss';

const propTypes = {
  location: PropTypes.object,
  children: PropTypes.object
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vwoCampaignData: {}
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        ...this.state,
        vwoCampaignData: window._vwo_campaignData
      });
    }
  }

  render() {
    const { children, location } = this.props;

    const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { ...this.state }));

    return (
      <div>
        <Header basePath={process.env.NODE_ENV === 'production' ? '/account' : ''} />
        {location && location.pathname === '/' && (
          <CookieBannerTemplate
            cookieName="tp-signup-cookie"
            render={(setCookie, isCookieSet) => <TpSignUpBanner setCookie={setCookie} isCookieSet={isCookieSet} />}
          />
        )}
        <div className="main">{childrenWithProps}</div>
        <CookieBannerTemplate
          cookieName="ratedpeople-cookie-acceptance"
          render={(setCookie, isCookieSet) => <CookiePrivacyBanner setCookie={setCookie} isCookieSet={isCookieSet} />}
        />
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = propTypes;

export default Layout;
