import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const propTypes = {
  isCookieSet: PropTypes.bool,
  setCookie: PropTypes.func
};

const TpSignUpBanner = (props) => (
  <div className={`${style['tp-banner-container']} ${props.isCookieSet ? style.hide : ''}`}>
    <span className={style['tp-banner-title']}>
      Become a verified tradesperson
    </span>
    <a
      className={style['tp-banner-button']}
      href="/tradesmen"
    >
      Find out how
    </a>
    <div
      className={style['tp-banner-close']}
      onClick={props.setCookie}
      onKeyUp={props.setCookie}
      tabIndex={0}
    />
  </div>
);

TpSignUpBanner.propTypes = propTypes;

export default TpSignUpBanner;
